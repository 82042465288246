/****   http.js   ****/
// 导入封装好的axios实例
import request from './request.js';
import Cookies from "js-cookie";

const http = {
	/**
	 * methods: 请求
	 * @param url 请求地址 
	 * @param params 请求参数
	 */
	get(url, params) {
		const config = {
			method: 'get',
			url: url
		}
		if (params) config.params = params
		return request(config)
	},
	post(url, params) {
		const config = {
			method: 'post',
			url: url
		}
		if (params) config.data = params
		return request(config)
	},
	put(url, params) {
		const config = {
			method: 'put',
			url: url
		}
		if (params) config.params = params
		return request(config)
	},
	delete(url, params) {
		const config = {
			method: 'delete',
			url: url
		}
		if (params) config.params = params
		return request(config)
	},
	// 时间戳转时间格式
	timeFormat(time, type, interval) {
		let int = interval || '-'
		var skr = new Date(time)
		let y = skr.getFullYear()
		let M = skr.getMonth() + 1
		let d = skr.getDate()

		let h = skr.getHours()
		let m = skr.getMinutes()
		let s = skr.getSeconds()
		M = M <= 9 ? '0' + M : M
		d = d <= 9 ? '0' + d : d
		h = h <= 9 ? '0' + h : h
		m = m <= 9 ? '0' + m : m
		s = s <= 9 ? '0' + s : s
		if (type == 'day' || type == '') {
			return y + int + m + int + d
		}
		if (type == 'second') {
			return y + int + M + int + d + ' ' + h + ':' + m + ':' + s
		}
	},
	getToken(url, params) {
		return Cookies.get('token')
	},
}
//导出
export default http