import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
// import './utils/rem.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Cookies from "js-cookie";
import http from './utils/http.js'

Vue.prototype.$cookies = Cookies;
Vue.prototype.http = http;

Vue.use(ElementUI);

Vue.config.productionTip = false;

router.beforeEach(function(to, from, next) {
	document.title = to.name;
	const TOKEN = Cookies.get('token')
	if (TOKEN) {
		if (to.path === '/login') next('/')
		else next()
	} else {
		// 没有token 只能访问登录页面 其它页面无法访问
		if (to.path === '/login' || to.path === '/user-agreement') next()
		else next('/login')
	}
})

new Vue({
	router,
	store,
	data: {
		currentId: '',
		token: '',
	},
	render: h => h(App)
}).$mount('#app')