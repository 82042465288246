<template>
	<div>
		<div class="navBar">
			<img src="../assets/h5-logo.png" alt="" class="logo" @click="goHome" />
			<img src="../assets/more.png" alt="" class="more" id="more-icon" @click="openTab" />
			<img src="../assets/close.png" alt="" class="close" id="close-icon" @click="closeTab" />
			<div class="tabs-list" id="tabs-list">
				<div class="item" v-for="(item,index) in tabList" :key="index" @click="selectTab(item)">
					<span :class="selectName == item.url?'select-item':''">{{item.name}}</span>
					<img src="../assets/tab-arrow.png" alt="" />
				</div>
			</div>
		</div>
		<div class="mask-layer" v-if="maskShow"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				maskShow: false,
				selectName: '/',
				tabList: [{
					name: '课程中心',
					url: '/'
				}, {
					name: '我的课程',
					url: '/my-course',
				}, {
					name: '我的留言',
					url: '/my-comment',
				}],
			}
		},
		watch: {
			'$route': {
				handler(to, from) {
					this.$cookies.set('selectName', to.path);
					this.selectName = to.path;
				},
				deep: true,
				immediate: true,
			}
		},
		mounted() {
			this.selectName = this.$cookies.get('selectName');
		},
		methods: {
			goHome(){
				this.$router.replace('/');
			},
			selectTab(item){
				this.selectName = item.url;
				this.$cookies.set('selectName', item.url);
				this.maskShow = false;
				let tabsList = document.getElementById('tabs-list');
				let moreIcon = document.getElementById('more-icon');
				let closeIcon = document.getElementById('close-icon');
				tabsList.style.height = '0px';
				moreIcon.style.display = 'block';
				closeIcon.style.display = 'none';
				this.$router.push(item.url);
			},
			openTab() {
				this.maskShow = true;
				let tabsList = document.getElementById('tabs-list');
				let moreIcon = document.getElementById('more-icon');
				let closeIcon = document.getElementById('close-icon');
				tabsList.style.height = '260px';
				moreIcon.style.display = 'none';
				closeIcon.style.display = 'block';
			},
			closeTab() {
				this.maskShow = false;
				let tabsList = document.getElementById('tabs-list');
				let moreIcon = document.getElementById('more-icon');
				let closeIcon = document.getElementById('close-icon');
				tabsList.style.height = '0px';
				moreIcon.style.display = 'block';
				closeIcon.style.display = 'none';
			}
		}
	}
</script>

<style scoped lang="scss">
	.mask-layer {
		width: calc(750px * 2.56);
		height: calc(100vh);
		background-color: #000000;
		opacity: 0.5;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 911;
	}

	.navBar {
		width: calc(750px * 2.56);
		height: calc(98px * 2.56);
		background-color: #FFFFFF;
		padding: 0 calc(30px * 2.56);
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;

		.logo {
			width: calc(291px * 2.56);
		}

		.more {
			width: calc(52px * 2.56);
			height: calc(52px * 2.56);
			display: block;
			transition: 1s;
		}

		.close {
			width: calc(52px * 2.56);
			height: calc(52px * 2.56);
			transition: 1s;
			display: none;
		}

		.tabs-list {
			width: calc(750px * 2.56);
			// height: calc(450px * 2.56);
			height: 0px;
			position: absolute;
			top: calc(98px * 2.56);
			left: 0;
			overflow: hidden;
			transition: height 0.6s;
			z-index: 1000;
			background-color: #FFFFFF;

			.item {
				width: calc(690px * 2.56);
				height: calc(150px * 2.56);
				border-bottom: calc(1px * 2.56) solid #D9D9D9;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: auto;

				span {
					font-weight: 500;
					font-size: calc(30px * 2.56);
					color: #151515;
				}

				.select-item {
					color: #3B84FE;
				}

				img {
					width: calc(52px * 2.56);
					height: calc(52px * 2.56);
				}
			}
		}
	}
</style>