<template>
	<div>
		<div class="header">
			<div class="header-left">
				<div class="title" @click="goHome">
					<img src="../assets/logo.png" alt="" />
					<div class="title-span">华成博创教育集团</div>
				</div>
				<div class="category">
					<div class="name">类别</div>
					<div class="value" @click="selectShow = !selectShow">
						{{currentName}}
						<img src="../assets/select.png" alt="" />
					</div>
					<div class="category-list" v-if="selectShow">
						<div class="item" v-for="(item,index) in dataList" :key="index" @click="selectItem(item)">
							<span>{{item.name}}</span>
							<img src="../assets/current.png" alt="" v-if="currentId == item.id" />
						</div>
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="tabs-list">
					<div :class="selectName == item.url?'items':'item'" v-for="(item,index) in tabList" :key="index"
						@click="selectTab(item,index)">
						{{item.name}}
					</div>
				</div>
				<img src="../assets/message.png" alt="" class="message-icon" v-if="!messageShow" @click="goMessage" />
				<img src="../assets/messages.png" alt="" class="message-icon" v-if="messageShow" @click="goMessage" />
				<div class="user-data" @click="dialogVisible = !dialogVisible">
					<img :src="userList.avatar" alt="" class="avatar" />
					<div class="phone">{{userList.username}}</div>
					<img src="../assets/down-arrow.png" alt="" class="down-arrow" />
				</div>
			</div>
			<el-dialog top="66px" :visible.sync="dialogVisible" :show-close="false" custom-class="dialogClass"
				width="240px">
				<div class="dialog-text">
					<el-upload class="upload-demo" action="https://course.biqimaker.com/api/common/upload" :limit="1"
						:on-success="onSuccess" :show-file-list="false">
						<div class="dialog-avatar">
							<img :src="userList.avatar" alt="" class="avatar" />
							<img src="../assets/camera.png" alt="" class="camera" />
						</div>
					</el-upload>
					<div class="dialog-name">
						{{userList.username}}
					</div>
					<div class="dialog-select" @click="cipherShow = true">
						<span>修改密码</span>
						<img src="../assets/link.png" alt="" />
					</div>
					<div class="dialog-btn" @click="logOut">
						退出登录
					</div>
				</div>
			</el-dialog>
			<el-dialog top="300px" :visible.sync="cipherShow" :show-close="false" custom-class="cipherClass"
				width="480px">
				<div class="cipher-text">
					<img src="../assets/close.png" alt="" class="close-icon" @click="cipherShow = false" />
					<div class="cipher-name">
						修改密码
					</div>
					<div class="cipher-form">
						<el-form label-position="left" :rules="rules" label-width="110px" :model="form" ref="form">
							<el-form-item label="密码" prop="password">
								<el-input v-model="form.password" placeholder="请输入登录密码"></el-input>
							</el-form-item>
							<el-form-item label="新密码" prop="pass">
								<el-input v-model="form.pass" placeholder="请输入登录密码"></el-input>
							</el-form-item>
							<el-form-item label="确认密码" prop="pwd">
								<el-input v-model="form.pwd" placeholder="请再次输入新密码"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="cipher-btn" @click="submitForm">
						确认修改
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入登录密码'));
				} else if (value !== this.form.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				tabList: [{
					name: '课程中心',
					url: '/'
				}, {
					name: '我的课程',
					url: '/my-course'
				}, {
					name: '我的留言',
					url: '/my-comment'
				}],
				selectShow: false,
				selectName: '/',
				currentName: '',
				currentId: '',
				dataList: [],
				userList: {},
				messageShow: false,
				dialogVisible: false,
				cipherShow: false,
				form: {
					password: '',
					pass: '',
					pwd: '',
				},
				rules: {
					password: [{
						required: true,
						message: '请输入登录密码',
						trigger: 'blur'
					}],
					pass: [{
						required: true,
						message: '请输入登录密码',
						trigger: 'blur'
					}],
					pwd: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}]
				}
			}
		},
		watch: {
			'$route': {
				handler(to, from) {
					this.$cookies.set('selectName', to.path);
					this.selectName = to.path;
				},
				deep: true,
				immediate: true,
			}
		},
		mounted() {
			this.currentName = this.$cookies.get('currentName');
			this.currentId = this.$cookies.get('currentId');
			this.selectName = this.$cookies.get('selectName');
			this.getList();
			this.getUser();
		},
		methods: {
			goHome() {
				this.$router.replace('/');
			},
			onSuccess(e) {
				this.userList.avatar = e.data.fullurl;
				this.submitFormAvatar();
			},
			submitFormAvatar() {
				this.http.post('/api/user/profile', {
					avatar: this.userList.avatar
				}).then(res => {
					if (res.code == 1) {

					} else {
						this.$message({
							message: res.msg,
							type: 'warning',
							customClass: 'messageTost',
							offset: 80,
						});
					}
				})
			},
			submitForm() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.http.post('/api/user/change_pwd', this.form).then(res => {
							if (res.code == 1) {
								this.dialogVisible = false;
								this.cipherShow = false;
								this.$message({
									message: '修改成功',
									type: 'success',
									customClass: 'messageTost',
									offset: 80,
								});
								this.$cookies.set('token', '');
								this.$router.replace('/login');
							} else {
								this.$message({
									message: res.msg,
									type: 'warning',
									customClass: 'messageTost',
									offset: 80,
								});
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			selectTab(item, index) {
				this.selectName = item.url;
				this.$cookies.set('selectName', item.url);
				this.$router.push(item.url);
			},
			selectItem(item) {
				this.currentName = item.name;
				this.currentId = item.id;
				this.$cookies.set('currentName', item.name);
				this.$cookies.set('currentId', item.id);
				this.$root.currentId = item.id;
				this.selectShow = false;
			},
			getList() {
				this.http.get('/api/index/index').then(res => {
					if (res.code == 1) {
						if (!this.currentId) {
							this.currentName = res.data.basic[0].name;
							this.currentId = res.data.basic[0].id;
						}
						if (res.data.num) {
							this.messageShow = true;
						}
						this.dataList = res.data.basic;
					}
				})
			},
			getUser() {
				this.http.get('/api/user/get_user').then(res => {
					if (res.code == 1) {
						this.userList = res.data;
					}
				})
			},
			logOut() {
				this.$cookies.set('token', '');
				this.$router.replace('/login');
				this.dialogVisible = false;
			},
			goMessage() {
				this.selectName = '';
				this.$cookies.set('selectName', '');
				this.$router.push('/message')
			}
		}
	}
</script>

<style scoped lang="scss">
	.header {
		width: 1920px;
		height: 60px;
		padding: 0 30px;
		background: #FFFFFF;
		box-shadow: 0px 1px 5px 0px rgba(153, 153, 153, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9998;

		.header-left {
			width: 500px;
			display: flex;
			align-items: center;

			.title {
				width: 200px;
				display: flex;
				align-items: center;
				cursor: pointer;

				img {
					width: 40px;
					height: 40px;
					margin-right: 5px;
				}

				.title-span {
					font-weight: bold;
					font-size: 18px;
					color: #000000;
				}
			}

			.category {
				display: flex;
				align-items: center;
				margin-left: 65px;
				position: relative;
				cursor: pointer;

				.name {
					font-weight: 400;
					font-size: 16px;
					color: #666666;
				}

				.value {
					display: flex;
					font-weight: 500;
					font-size: 16px;
					color: #151515;
					margin-left: 8px;

					img {
						width: 10px;
						height: 10px;
						margin-top: 10px;
					}
				}

				.category-list {
					position: absolute;
					top: 30px;
					left: 40px;
					width: 180px;
					height: 303px;
					padding: 8px 16px;
					background: #FFFFFF;
					box-shadow: 0px 1px 5px 0px rgba(153, 153, 153, 0.3);
					border-radius: 8px 8px 8px 8px;
					overflow: auto;

					.item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 25px;
						margin: 8px 0;

						span {
							font-weight: 500;
							font-size: 14px;
							color: #999999;
						}

						img {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}

		.header-right {
			display: flex;
			align-items: center;

			.tabs-list {
				display: flex;
				align-items: center;

				.item {
					font-weight: 500;
					font-size: 16px;
					color: #151515;
					margin: 0 25px;
					cursor: pointer;
				}

				.items {
					font-weight: bold;
					font-size: 16px;
					color: #0E71FF;
					margin: 0 25px;
					cursor: pointer;
				}
			}

			.message-icon {
				margin-left: 47px;
				width: 32px;
				height: 32px;
				cursor: pointer;
			}

			.user-data {
				display: flex;
				align-items: center;
				margin-left: 43px;
				cursor: pointer;

				.avatar {
					width: 32px;
					height: 32px;
					border-radius: 50%;
				}

				.phone {
					margin: 0 14px;
					font-weight: 400;
					font-size: 16px;
					color: #000000;
				}

				.down-arrow {
					width: 14px;
					height: 12px;
				}
			}
		}

		::v-deep .dialogClass {
			width: 240px;
			border-radius: 8px;
			position: absolute;
			right: 30px;
		}

		::v-deep .el-dialog__header {
			padding: 0;
		}

		::v-deep .el-dialog__body {
			padding: 0;
		}

		.dialog-text {
			width: 100%;
			padding: 30px 0;

			.upload-demo {
				margin: auto;
				text-align: center;
			}

			.dialog-avatar {
				width: 62px;
				height: 62px;
				position: relative;
				margin: auto;
				cursor: pointer;

				.avatar {
					width: 62px;
					height: 62px;
					border-radius: 50%;
				}

				.camera {
					width: 20px;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}

			.dialog-name {
				font-weight: 500;
				font-size: 20px;
				color: #000000;
				text-align: center;
				padding: 14px 0;
			}

			.dialog-select {
				width: 90%;
				height: 50px;
				margin: auto;
				border-top: 1px solid #D9D9D9;
				border-bottom: 1px solid #D9D9D9;
				cursor: pointer;
				position: relative;

				span {
					font-weight: 500;
					font-size: 16px;
					color: #000000;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				img {
					width: 20px;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}
			}

			.dialog-btn {
				width: 70%;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #0E71FF;
				border-radius: 42px 42px 42px 42px;
				font-weight: 500;
				font-size: 14px;
				color: #FFFFFF;
				margin: auto;
				margin-top: 35px;
				cursor: pointer;
			}
		}

		::v-deep .cipherClass {
			border-radius: 16px;
		}


		.cipher-text {
			padding-bottom: 26px;
			position: relative;

			.close-icon {
				width: 28px;
				position: absolute;
				top: 20px;
				right: 30px;
			}

			.cipher-name {
				font-weight: 500;
				font-size: 18px;
				color: #000000;
				text-align: center;
				padding-top: 20px;
			}

			.cipher-form {
				padding: 20px 46px;

				::v-deep .el-form-item__label {
					font-weight: 500;
					font-size: 14px;
					color: #000000;
				}
			}

			.cipher-btn {
				width: 176px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #0E71FF;
				border-radius: 42px 42px 42px 42px;
				font-weight: 500;
				font-size: 14px;
				color: #FFFFFF;
				margin: auto;
			}
		}

	}
</style>