<template>
	<div>
		<div class="pc-home" v-if="innerWidth >= 1200">
			<navigationBar></navigationBar>
			<div class="dataList" v-for="(item,index) in dataList" :key="index">
				<div class="title">
					<img :src="item.image" alt="" />
					<span>{{item.name}}</span>
				</div>
				<div class="text-list">
					<div class="item" :style="{'background': it.color}" v-for="(it,i) in item.class" :key="i"
						@mouseenter.lazy="handleMouseEnter(it,i,index)" @mouseleave.lazy="handleMouseLeave(it,i,index)"
						@click="goDetails(item,it)">
						<div class="item-text">
							<div class="name">{{it.name}}</div>
							<div class="time">{{it.time_arr}}</div>
							<div class="content">
								<!-- {{it.content}} -->
								<div v-html="it.content"></div>
							</div>
						</div>
						<div class="item-video">
							<img draggable="false" :id="'img'+ it.id + i + index" :src="it.image" alt="" v-if="it.image"
								:class="it.is_free == 1?'course-cover-img':'course-cover-img img-filter'">
							<video :id="'video'+ it.id + i + index" loop :src="it.video" class="video" v-if="it.video"
								muted></video>
							<div class="course-cover-gradient"
								:style="{'background': 'linear-gradient(270deg, rgba(255, 255, 255, 0) 0.52%,'+ it.color +' 98.93%)'}">
							</div>
							<img src="../assets/the-lock.png" alt="" class="the-lock" v-if="it.is_free == 0" />
						</div>
					</div>
				</div>
			</div>
			<el-empty description="暂无课程" v-if="dataList.length == 0" style="margin-top: 260px;"></el-empty>
			<el-dialog :visible.sync="dialogVisible" width="480px" custom-class="agreementClass" :show-close="false">
				<div class="chapter-tost">
					<img src="../assets/chapter-lock.png" alt="" class="chapter-lock" />
					<img src="../assets/close.png" alt="" class="close-icon" @click="dialogVisible = false" />
					<div class="tost-text">
						此课程未购买 </br>
						请联系管理员购买该课程
					</div>
					<div class="btn" @click="dialogVisible = false">
						我知道了
					</div>
				</div>
			</el-dialog>
		</div>
		<div class="h5-home" v-if="innerWidth<1200">
			<navigationBarH5></navigationBarH5>
			<div class="user-data">
				<div class="user" @click="goUser">
					<img :src="userList.avatar" alt="" />
					<div>{{userList.username}}</div>
				</div>
				<img src="../assets/message.png" alt="" class="message-icon" v-if="!messageShow" @click="goMessage" />
				<img src="../assets/messages.png" alt="" class="message-icon" v-if="messageShow" @click="goMessage" />
			</div>
			<div class="category" @click="selectCategory">
				<div class="label">类别</div>
				<div class="value">
					<span>{{currentName}}</span>
					<img src="../assets/right-arrow.png" alt="" />
				</div>
			</div>
			<div class="dataList" v-for="(item,index) in dataList" :key="index">
				<div class="title">
					<img :src="item.image" alt="" />
					<span>{{item.name}}</span>
				</div>
				<div class="text-list">
					<div class="item" :style="{'background': it.color}" v-for="(it,i) in item.class" :key="i"
						@click="goDetails(item,it)">
						<div class="item-text">
							<div class="name">{{it.name}}</div>
							<div class="time">{{it.time_arr}}</div>
							<div class="content">
								<!-- {{it.content}} -->
								<div v-html="it.content"></div>
							</div>
						</div>
						<div class="item-video">
							<img draggable="false" :id="'img'+i" :src="it.image" alt=""
								:class="it.is_free == 1?'course-cover-img':'course-cover-img img-filter'">
							<div class="course-cover-gradient" v-if="it.is_free == 1"
								:style="{'background': 'linear-gradient(270deg, rgba(255, 255, 255, 0) 0.52%,'+ it.color +' 98.93%)'}">
							</div>
							<img src="../assets/the-lock.png" alt="" class="the-lock" v-if="it.is_free == 0" />
						</div>
					</div>
				</div>
			</div>
			<el-empty description=" " v-if="dataList.length == 0" :image-size="120" style="margin-top: 200px;"
				class="custom-empty"></el-empty>
			<div class="mask-layer" v-if="maskShow"></div>
			<div class="pop-card" id="pop-card">
				<img src="../assets/pop-close.png" alt="" class="close-icon" @click="popClose" />
				<div class="name">选择类别</div>
				<div class="category-list">
					<div class="item" v-for="(item,index) in categoryList" :key="index" @click="selectItem(item)">
						<span>{{item.name}}</span>
						<img src="../assets/checks.png" alt="" v-if="currentIds == item.id" />
					</div>
				</div>
				<div class="btn" @click="confirmSelection">
					确认修改
				</div>
			</div>
			<el-dialog top="280px" :visible.sync="dialogVisible" width="335px" custom-class="agreementClass"
				:show-close="false">
				<div class="chapter-tost">
					<img src="../assets/chapter-lock.png" alt="" class="chapter-lock" />
					<img src="../assets/close.png" alt="" class="close-icon" @click="dialogVisible = false" />
					<div class="tost-text">
						此课程未购买 </br>
						请联系管理员购买该课程
					</div>
					<div class="btn" @click="dialogVisible = false">
						我知道了
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		Number
	} from 'core-js/web';
	import navigationBar from '../components/navigation-bar';
	import navigationBarH5 from '../components/navigation-bar-h5';
	export default {
		components: {
			navigationBar,
			navigationBarH5
		},
		data() {
			return {
				currentNames: '',
				currentIds: '',
				currentName: '',
				currentId: '',
				ids: '',
				img: '',
				video: '',
				dataList: [],
				innerWidth: '',
				messageShow: false,
				maskShow: false,
				categoryList: [],
				userList: {},
				dialogVisible: false,
				dialogVisibleH5: false,
				// 存储当前页面的滚动位置
				scrollPosition: 0,
			}
		},
		watch: {
			'$root.currentId'(val) {
				this.ids = val;
				this.dataList = [];
				this.getList();
			}
		},
		mounted() {
			let height = this.$cookies.get('scrollPosition');
			height = Number(height);
			setTimeout(() => {
				window.scrollTo({
					top: height,
					behavior: 'smooth' // 平滑滚动
				});
			}, 300)
			let that = this;
			that.currentName = this.$cookies.get('currentName');
			that.currentId = this.$cookies.get('currentId');
			that.getCategory();
			that.getUser();
			that.innerWidth = window.innerWidth;
			window.onresize = () => {
				return (() => {
					that.innerWidth = window.innerWidth;
					console.log(that.innerWidth);
				})()
			}
			that.ids = that.$cookies.get('currentId');
			if (!that.ids) {
				that.getCurrentList();
			} else {
				that.getList();
			}
			window.addEventListener('scroll', that.handleScroll);
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			handleScroll() {
				const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				this.$cookies.set('scrollPosition', scrollTop);
			},
			getCurrentList() {
				this.http.get('/api/index/index').then(res => {
					if (res.code == 1) {
						this.ids = res.data.basic[0].id;
						this.getList();
					}
				})
			},
			getCategory() {
				this.http.get('/api/index/index').then(res => {
					if (res.code == 1) {
						if (!this.currentId) {
							this.currentName = res.data.basic[0].name;
							this.currentId = res.data.basic[0].id;
						}
						if (res.data.num) {
							this.messageShow = true;
						}
						this.categoryList = res.data.basic;
					}
				})
			},
			getUser() {
				this.http.get('/api/user/get_user').then(res => {
					if (res.code == 1) {
						this.userList = res.data;
					}
				})
			},
			confirmSelection() {
				this.currentName = this.currentNames;
				this.currentId = this.currentIds;
				this.$cookies.set('currentName', this.currentName);
				this.$cookies.set('currentId', this.currentId);
				this.$root.currentId = this.currentId;
				this.maskShow = false;
				let popCard = document.getElementById('pop-card');
				popCard.style.height = '0px';
			},
			selectItem(item) {
				this.currentNames = item.name;
				this.currentIds = item.id;
			},
			getList() {
				this.http.get('/api/index/list', {
					ids: this.ids,
				}).then(res => {
					if (res.code == 1) {
						res.data.class_type.forEach((item, index) => {
							item.class.forEach((it, i) => {
								it.content = it.content.replace(/\r\n/g, '</br>');
							})
						})
						this.dataList = res.data.class_type;
					}
				})
			},
			handleMouseEnter(item, i, index) {
				if (item.is_free == 1 && item.video) {
					this.img = document.getElementById("img" + item.id + i + index);
					this.video = document.getElementById("video" + item.id + i + index);
					this.img.style.transition = 'transform 2s';
					this.img.style.transform = 'scale(1.1)';
					setTimeout(() => {
						this.video.play();
					}, 100)
					setTimeout(() => {
						this.img.style.display = 'none';
					}, 500)
				}
			},
			handleMouseLeave(item, i, index) {
				if (item.is_free == 1 && item.video) {
					this.img = document.getElementById("img" + item.id + i + index);
					this.video = document.getElementById("video" + item.id + i + index);
					this.img.style.transition = 'transform 0s';
					this.img.style.transform = 'scale(1)';
					this.img.style.display = 'block';
					setTimeout(() => {
						this.video.pause();
					}, 100)
					setTimeout(() => {
						this.img.style.display = 'block';
					}, 500)
				}
			},
			goDetails(item, it) {
				this.$router.push({
					path: '/directory-details',
					query: {
						ids: it.id,
					}
				})
				// if (it.is_free == 1) {
				// 	this.$router.push({
				// 		path: '/directory-details',
				// 		query: {
				// 			ids: it.id,
				// 		}
				// 	})
				// } else {
				// 	this.dialogVisible = true;
				// }
			},
			goUser() {
				this.$router.push('/personal-information')
			},
			goMessage() {
				this.$router.push('/message')
			},
			selectCategory() {
				this.maskShow = true;
				let popCard = document.getElementById('pop-card');
				popCard.style.height = '600px';
			},
			popClose() {
				this.maskShow = false;
				let popCard = document.getElementById('pop-card');
				popCard.style.height = '0px';
			}
		}
	}
</script>

<style scoped lang="scss">
	video::-webkit-media-controls-fullscreen-button {
		display: none;
	}

	::v-deep .agreementClass {
		border-radius: 16px;
		overflow: hidden;
	}

	::v-deep .el-dialog__header {
		padding: 0;
	}

	::v-deep .el-dialog__body {
		padding: 0;
	}

	.pc-home {
		padding: 68px 30px;

		.title {
			display: flex;
			align-items: center;

			img {
				width: 28px;
				height: 28px;
			}

			span {
				font-weight: 600;
				font-size: 16px;
				color: #000000;
				margin-left: 5px;
			}
		}

		.dataList {
			width: 1860px;
			margin-top: 20px;
		}

		.text-list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			.item {
				width: 922px;
				height: 180px;
				border-radius: 8px;
				margin: 8px 0;
				display: flex;
				justify-content: space-between;
				border-radius: 8px;
				cursor: pointer;
				overflow: hidden;

				.item-text {
					margin-left: 20px;
					margin-top: 28px;

					.name {
						font-weight: 800;
						font-size: 22px;
						color: #FFFFFF;
					}

					.time {
						font-weight: 400;
						font-size: 12px;
						color: hsla(0, 0%, 100%, .8);
						margin-top: 2px;
					}

					.content {
						width: 540px;
						font-weight: 500;
						font-size: 14px;
						color: hsla(0, 0%, 100%, .8);
						line-height: 18px;
						margin-top: 20px;
					}
				}

				.item-video {
					width: 360px;
					height: 180px;
					overflow: hidden;
					position: relative;

					.course-cover-img {
						width: 100%;
						height: 100%;
					}

					.video {
						width: 100%;
						height: 100%;
						object-fit: fill;
						border-radius: 8px;
					}

					.the-lock {
						position: absolute;
						width: 60px;
						// height: 60px;
						top: 50%;
						right: 72px;
						transform: translateY(-50%);
					}

					.img-filter {
						filter: blur(3px);
					}

					/* 隐藏video 所有控件 */
					.video::-webkit-media-controls-enclosure {
						display: none;
					}

					.course-cover-gradient {
						position: absolute;
						top: 0;
						left: 0;
						width: 80px;
						height: 100%;
					}
				}
			}
		}

		.chapter-tost {
			width: 480px;
			height: 300px;
			background: #FFFFFF;
			border-radius: 16px 16px 16px 16px;
			text-align: center;
			position: relative;

			.chapter-lock {
				width: 90px;
				height: 90px;
				margin-top: 37px;
			}

			.close-icon {
				width: 28px;
				height: 28px;
				position: absolute;
				top: 20px;
				right: 30px;
			}

			.tost-text {
				font-weight: 500;
				font-size: 14px;
				color: #000000;
				line-height: 20px;
				text-align: center;
				margin-top: 10px;
			}


			.btn {
				width: 200px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #0E71FF;
				border-radius: 50px 50px 50px 50px;
				font-weight: 500;
				font-size: 14px;
				color: #FFFFFF;
				margin: auto;
				margin-top: 20px;
			}
		}
	}

	.h5-home {
		min-height: 100vh;
		background-color: #FAFAFA;
		padding-top: calc(118px * 2.56);

		.user-data {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: calc(24px * 2.56) calc(30px * 2.56);

			.user {
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: calc(30px * 2.56);
				color: #000000;

				img {
					width: calc(58px * 2.56);
					height: calc(58px * 2.56);
					border-radius: 50%;
					margin-right: calc(20px * 2.56);
				}
			}

			.message-icon {
				width: calc(48px * 2.56);
				height: calc(48px * 2.56);
			}
		}

		.category {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(690px * 2.56);
			margin: auto;
			padding-top: calc(16px * 2.56);
			padding-bottom: calc(30px * 2.56);
			border-bottom: calc(1px * 2.56) solid #D9D9D9;

			.label {
				font-weight: 400;
				font-size: calc(28px * 2.56);
				color: #666666;
			}

			.value {
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: calc(28px * 2.56);
				color: #151515;

				img {
					width: calc(32px * 2.56);
					height: calc(32px * 2.56);
					margin-left: calc(10px * 2.56);
				}
			}
		}

		.dataList {
			padding: calc(30px * 2.56);

			.title {
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: calc(38px * 2.56);
				color: #000000;

				img {
					width: calc(54px * 2.56);
					height: calc(54px * 2.56);
					margin-right: calc(20px * 2.56);
				}
			}

			.text-list {
				margin: auto;

				.item {
					width: calc(689px * 2.56);
					height: calc(135px * 2.56);
					background: linear-gradient(88deg, #4A91F7 0%, rgba(74, 145, 247, 0) 100%);
					border-radius: calc(6px * 2.56);
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: calc(20px * 2.56);
					overflow: hidden;

					.item-text {
						width: calc(460px * 2.56);
						padding-left: calc(19px * 2.56);

						.name {
							font-weight: 800;
							font-size: calc(30px * 2.56);
							color: #FFFFFF;
						}

						.time {
							font-weight: 400;
							font-size: calc(14px * 2.56);
							color: #FFFFFF;
						}

						.content {
							font-weight: 500;
							font-size: calc(24px * 2.56);
							color: #FFFFFF;
							margin-top: calc(16px * 2.56);
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}

					.item-video {
						width: calc(240px * 2.56);
						height: calc(135px * 2.56);
						position: relative;

						.course-cover-img {
							width: 100%;
							height: 100%;
							border-radius: calc(8px * 2.56);
							display: block;
						}

						.img-filter {
							filter: blur(5px * 2.56);
						}

						.the-lock {
							position: absolute;
							width: calc(60px * 2.56);
							top: 50%;
							right: calc(72px * 2.56);
							transform: translateY(-50%);
						}

						.course-cover-gradient {
							position: absolute;
							top: 0;
							left: 0;
							width: calc(100px * 2.56);
							height: calc(135px * 2.56);
						}
					}
				}
			}
		}

		.mask-layer {
			width: calc(750px * 2.56);
			height: calc(100vh);
			background-color: #000000;
			opacity: 0.5;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 99;
		}

		.pop-card {
			width: calc(750px *2.56);
			background: #FFFFFF;
			border-radius: calc(24px * 2.56) calc(24px * 2.56) 0px 0px;
			z-index: 999;
			position: fixed;
			bottom: 0;
			height: 0px;
			transition: height 0.6s;
			overflow: hidden;

			.close-icon {
				width: calc(48px * 2.56);
				height: calc(48px * 2.56);
				position: absolute;
				right: calc(30px * 2.56);
				top: calc(45px * 2.56);
			}

			.name {
				font-weight: 500;
				font-size: calc(38px * 2.56);
				color: #000000;
				text-align: center;
				margin-top: calc(43px * 2.56);
			}

			.category-list {
				height: calc(720px * 2.56);
				overflow-y: auto;
				margin-top: calc(35px * 2.56);

				.item {
					width: calc(690px * 2.56);
					height: calc(102px * 2.56);
					border-bottom: calc(1px * 2.56) solid #D9D9D9;
					margin: auto;
					display: flex;
					align-items: center;
					justify-content: space-between;

					span {
						font-weight: 500;
						font-size: calc(30px * 2.56);
						color: #000000;
					}

					img {
						width: calc(44px * 2.56);
						height: calc(44px * 2.56);
					}
				}
			}

			.btn {
				width: calc(590px * 2.56);
				height: calc(88px * 2.56);
				line-height: calc(88px * 2.56);
				text-align: center;
				background: #0E71FF;
				border-radius: calc(100px * 2.56);
				font-weight: 500;
				font-size: calc(32px * 2.56);
				color: #FFFFFF;
				margin: auto;
				margin-top: calc(70px * 2.56);
			}
		}

		::v-deep .agreementClass {
			border-radius: calc(16px * 2.56) !important;
			overflow: hidden;
		}

		.chapter-tost {
			width: calc(580px * 2.56);
			height: calc(399px * 2.56);
			background: #FFFFFF;
			border-radius: calc(16px * 2.56);
			position: relative;
			text-align: center;

			.chapter-lock {
				width: calc(120px * 2.56);
				height: calc(120px * 2.56);
				margin-top: calc(40px * 2.56);
			}

			.close-icon {
				width: calc(44px * 2.56);
				height: calc(44px * 2.56);
				position: absolute;
				top: calc(30px * 2.56);
				right: calc(30px * 2.56);
			}

			.tost-text {
				font-weight: 500;
				font-size: calc(28px * 2.56);
				color: #333333;
				margin-top: calc(20px * 2.56);
			}

			.btn {
				width: calc(380px * 2.56);
				height: calc(72px * 2.56);
				line-height: calc(72px * 2.56);
				background: #0E71FF;
				border-radius: calc(78px * 2.56);
				text-align: center;
				font-weight: 500;
				font-size: calc(30px * 2.56);
				color: #FFFFFF;
				margin: auto;
				margin-top: calc(30px * 2.56);
			}
		}
	}
</style>