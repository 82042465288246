import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import Cookies from "js-cookie";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


const routes = [{
		path: '/',
		name: '课程中心',
		component: HomeView,
		title: '课程中心',
	},
	{
		path: '/login',
		name: '华成博创教育集团',
		component: () => import('../views/login.vue'),
		title: '华成博创教育集团',
	},
	{
		path: '/directory-details',
		name: '目录详情',
		component: () => import('../views/directory-details.vue'),
		title: '目录详情',
	},
	{
		path: '/message',
		name: '消息',
		component: () => import('../views/message.vue'),
		title: '消息',
	},
	{
		path: '/my-course',
		name: '我的课程',
		component: () => import('../views/my-course.vue'),
		title: '我的课程',
	},
	{
		path: '/my-comment',
		name: '我的留言',
		component: () => import('../views/my-comment.vue'),
		title: '我的留言',
	},
	{
		path: '/course-details',
		name: '章节详情',
		component: () => import('../views/course-details.vue'),
		title: '章节详情',
	},
	{
		path: '/user-agreement',
		name: '用户协议',
		component: () => import('../views/user-agreement.vue'),
		title: '用户协议',
	},
	{
		path: '/personal-information',
		name: '个人信息',
		component: () => import('../views/personal-information.vue'),
		title: '个人信息',
	}
]

const router = new VueRouter({
	routes,
})

export default router