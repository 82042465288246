<template>
	<div>
		<div style="height:100%;width: 100%;position:fixed;left:0px;top:0px;overflow: hidden;z-index: 1;pointer-events: none;
    z-index: 9999999;opacity: 0.2;" class="u-f u-f-spa">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
				v-for="(item,index) in 24" :key="index">
				<text id="txt" x="160" y="150" style="font-size:12px;" fill-opacity="0.4" text-anchor='middle'
					font-size='14' dominant-baseline='middle' transform='rotate(-35, 100 100)'>
					华成博创教育科技有限公司
				</text>
				<text id="txt" x="160" y="135" style="font-size:12px;" fill-opacity="0.4" text-anchor='middle'
					font-size='14' dominant-baseline='middle' transform='rotate(-35, 100 100)'>
					{{text + ' ' + time}}
				</text>
			</svg>
		</div>
		<router-view />
	</div>
</template>

<script>
	import navigationBar from './components/navigation-bar';
	export default {
		components: {
			navigationBar
		},
		data() {
			return {
				text: '',
				time: '',
			}
		},
		watch: {
			'$root.token'(val) {
				this.getUser();
			}
		},
		created() {
			let token = this.$cookies.get('token');
			if (token) {
				this.getUser();
			}
		},
		methods: {
			getUser() {
				this.http.get('/api/user/get_user').then(res => {
					if (res.code == 1) {
						this.text = res.data.username;
						const now = new Date();
						const year = now.getFullYear();
						const month = now.getMonth() + 1; // 月份从0开始，所以要加1
						const day = now.getDate();
						const hour = now.getHours();
						const minute = now.getMinutes();
						const second = now.getSeconds();
						this.time = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
						console.log(this.text, this.time);
					}
				})
			},
		}
	};
</script>

<style lang="scss">
	body,
	html,
	p,
	ul,
	li,
	div {
		margin: 0px;
		padding: 0px;
		height: auto;
	}

	div {
		box-sizing: border-box;
	}

	html {
		overflow-x: hidden;
	}

	.pc-text {
		padding-top: 60px;
	}

	.el-input__inner {
		border: none;
		background-color: #F5F6FA !important;
	}

	.u-f,
	.u-f-ac {
		display: flex !important;
	}

	.u-f-spa {
		flex-direction: row !important;
		flex-wrap: wrap !important;
	}
</style>